const BasketSmall = ({ size, stroke, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || '16'}
      height={size || '16'}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2.6665 3.42857H13.3332M6.47603 5.71429V11.8095M9.52365 5.71429V11.8095M4.19031 3.42857H11.8094V12.5714C11.8094 12.9756 11.6488 13.3632 11.363 13.6489C11.0773 13.9347 10.6897 14.0952 10.2856 14.0952H5.71412C5.30998 14.0952 4.9224 13.9347 4.63663 13.6489C4.35086 13.3632 4.19031 12.9756 4.19031 12.5714V3.42857ZM7.99984 1.90476C8.38428 1.90464 8.75455 2.04983 9.03645 2.31124C9.31834 2.57264 9.491 2.93093 9.51984 3.31429L9.52365 3.42857H6.47603C6.47603 3.02443 6.63657 2.63685 6.92234 2.35108C7.20811 2.06531 7.5957 1.90476 7.99984 1.90476Z"
        stroke={stroke || '#191C2C'}
        strokeWidth="0.761905"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BasketSmall;
