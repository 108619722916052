import { useState, useEffect } from 'react';
import Button from 'shared/components/Button';
import styles from './AddToHomeScreenPrompt.module.scss';

const AddToHomeScreenPrompt = () => {
  const [promptEvent, setPromptEvent] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = event => {
      event.preventDefault();
      setPromptEvent(event);
      setIsVisible(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (promptEvent) {
      promptEvent.prompt();
      promptEvent.userChoice.then(choiceResult => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        setPromptEvent(null);
        setIsVisible(false);
      });
    }
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.container}>
      <p>
        Додай додаток на головний екран,
        <br /> так буде значно зручніше :)
      </p>
      <div className={styles.buttonsContainer}>
        <Button onClick={handleInstallClick}>Додати на головний екран</Button>
        <Button onClick={handleClose}>Закрити</Button>
      </div>
    </div>
  );
};

export default AddToHomeScreenPrompt;
