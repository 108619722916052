export default function UserLight({ width = 32, height = 32 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M28.6488 26.625C26.6725 23.21 23.57 20.8137 19.965 19.7975C21.7177 18.9066 23.1192 17.4513 23.9436 15.6663C24.7681 13.8814 24.9673 11.8708 24.5092 9.95881C24.0511 8.04678 22.9625 6.34476 21.4187 5.12717C19.875 3.90959 17.9661 3.24741 16 3.24741C14.0339 3.24741 12.125 3.90959 10.5813 5.12717C9.03755 6.34476 7.94887 8.04678 7.4908 9.95881C7.03272 11.8708 7.23196 13.8814 8.05637 15.6663C8.88078 17.4513 10.2823 18.9066 12.035 19.7975C8.43 20.8125 5.32751 23.2087 3.35125 26.625C3.29699 26.7104 3.26055 26.8058 3.24414 26.9056C3.22772 27.0054 3.23166 27.1075 3.25573 27.2058C3.27979 27.304 3.32348 27.3964 3.38417 27.4773C3.44486 27.5582 3.52128 27.6261 3.60886 27.6767C3.69643 27.7273 3.79334 27.7597 3.89375 27.7719C3.99417 27.7841 4.09601 27.7758 4.19316 27.7476C4.2903 27.7195 4.38075 27.6719 4.45904 27.6079C4.53734 27.5438 4.60187 27.4646 4.64875 27.375C7.05 23.2262 11.2925 20.75 16 20.75C20.7075 20.75 24.95 23.2262 27.3513 27.375C27.3981 27.4646 27.4627 27.5438 27.541 27.6079C27.6193 27.6719 27.7097 27.7195 27.8069 27.7476C27.904 27.7758 28.0058 27.7841 28.1063 27.7719C28.2067 27.7597 28.3036 27.7273 28.3912 27.6767C28.4787 27.6261 28.5552 27.5582 28.6158 27.4773C28.6765 27.3964 28.7202 27.304 28.7443 27.2058C28.7683 27.1075 28.7723 27.0054 28.7559 26.9056C28.7395 26.8058 28.703 26.7104 28.6488 26.625ZM8.75001 12C8.75001 10.5661 9.17521 9.16436 9.97185 7.9721C10.7685 6.77985 11.9008 5.8506 13.2255 5.30186C14.5503 4.75313 16.008 4.60955 17.4144 4.88929C18.8208 5.16904 20.1126 5.85953 21.1265 6.87346C22.1405 7.88739 22.831 9.17922 23.1107 10.5856C23.3904 11.9919 23.2469 13.4497 22.6981 14.7744C22.1494 16.0992 21.2201 17.2315 20.0279 18.0281C18.8356 18.8248 17.4339 19.25 16 19.25C14.0779 19.2477 12.2352 18.4831 10.876 17.124C9.5169 15.7648 8.75232 13.9221 8.75001 12Z"
        fill="#2B2A29"
      />
    </svg>
  );
}
