export default function MobileMenuIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      // fill="none"
      onClick={props.onClick}
    >
      <path
        d="M28.4444 25.7778H3.55555C3.3198 25.7778 3.0937 25.6841 2.92701 25.5174C2.76031 25.3507 2.66666 25.1246 2.66666 24.8889C2.66666 24.6531 2.76031 24.427 2.92701 24.2603C3.0937 24.0936 3.3198 24 3.55555 24H28.4444C28.6802 24 28.9063 24.0936 29.073 24.2603C29.2397 24.427 29.3333 24.6531 29.3333 24.8889C29.3333 25.1246 29.2397 25.3507 29.073 25.5174C28.9063 25.6841 28.6802 25.7778 28.4444 25.7778Z"
        // fill="#2B2A29"
      />
      <path
        d="M28.4444 16.8889H3.55555C3.3198 16.8889 3.0937 16.7952 2.92701 16.6285C2.76031 16.4618 2.66666 16.2358 2.66666 16C2.66666 15.7643 2.76031 15.5382 2.92701 15.3715C3.0937 15.2048 3.3198 15.1111 3.55555 15.1111H28.4444C28.6802 15.1111 28.9063 15.2048 29.073 15.3715C29.2397 15.5382 29.3333 15.7643 29.3333 16C29.3333 16.2358 29.2397 16.4618 29.073 16.6285C28.9063 16.7952 28.6802 16.8889 28.4444 16.8889Z"
        // fill="#2B2A29"
      />
      <path
        d="M28.4444 8.00001H3.55555C3.3198 8.00001 3.0937 7.90636 2.92701 7.73966C2.76031 7.57296 2.66666 7.34687 2.66666 7.11112C2.66666 6.87537 2.76031 6.64928 2.92701 6.48258C3.0937 6.31588 3.3198 6.22223 3.55555 6.22223H28.4444C28.6802 6.22223 28.9063 6.31588 29.073 6.48258C29.2397 6.64928 29.3333 6.87537 29.3333 7.11112C29.3333 7.34687 29.2397 7.57296 29.073 7.73966C28.9063 7.90636 28.6802 8.00001 28.4444 8.00001Z"
        // fill="#2B2A29"
      />
    </svg>
  );
}
