import styles from './image.module.scss';

import defaultImage from './defaultImage.png';
import { useState } from 'react';

const Image = props => {
  const {
    src,
    alt = 'Смаколик',
    height,
    width,
    className,
    style,
    text = '',
    id,
    retinaImage,
    thumbnail,
    // fontSize,
  } = props;
  const imageStyles = {
    ...style,
  };

  const imageDetecting = src => {
    if (Array.isArray(src) && src.length > 0) {
      return src[0];
    }
    if (typeof src === 'string') {
      return src;
    }
    return defaultImage;
  };

  const [image, setImage] = useState(imageDetecting(src) || defaultImage);

  const [retinaImage1, setRetinaImage1] = useState(retinaImage || false);
  const [thumbnail1, setThumbnail1] = useState(thumbnail || false);
  // console.log('src :>> ', src);
  // console.log('typeof src :>> ', typeof src);
  // const letterAvatar = text.substring(0, 1).toUpperCase();
  // console.log(letterAvatar);

  const getFileExtension = filename => {
    return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
  };
  const extension = getFileExtension(image);
  // const image = imageDetecting(imageSrc);
  const handleImageError = () => {
    // console.log('handleImageError', image);
    setRetinaImage1(false);
    setImage(retinaImage ? image : defaultImage);
  };
  const handleImageThumbnailError = () => {
    console.log('handleImageThumnailError', image);
    setThumbnail1(false);
    // setImage(setThumbnail1 ? image : defaultImage);
  };

  const pictures = retinaImage1 ? (
    <picture>
      <source
        srcSet={`${image.slice(
          0,
          -extension.length - 1
        )}_small.${extension} 1x, ${image.slice(
          0,
          -extension.length - 1
        )}_small@2x.${extension} 2x, ${image.slice(
          0,
          -extension.length - 1
        )}_small@3x.${extension} 3x`}
        media="(max-width: 767px)"
      />
      <source
        srcSet={`${image.slice(
          0,
          -extension.length - 1
        )}_medium.${extension} 1x, ${image.slice(
          0,
          -extension.length - 1
        )}_medium@2x.${extension} 2x, ${image.slice(
          0,
          -extension.length - 1
        )}_medium@3x.${extension} 3x`}
        media="(max-width: 1199px)"
      />
      <source
        srcSet={`${image.slice(
          0,
          -extension.length - 1
        )}_large.${extension} 1x, ${image.slice(
          0,
          -extension.length - 1
        )}_large@2x.${extension} 2x, ${image.slice(
          0,
          -extension.length - 1
        )}_large@3x.${extension} 3x`}
        media="(min-width: 1280px)"
      />
      <img
        id={id}
        className={`${styles.img} ${className ? className : ''}`}
        style={imageStyles}
        src={image}
        alt={alt}
        height={height}
        width={width}
        loading="lazy"
        onError={handleImageError}
      />
    </picture>
  ) : thumbnail1 ? (
    <img
      id={id}
      className={`${styles.img} ${className ? className : ''}`}
      style={imageStyles}
      src={
        `${image.slice(0, -extension.length - 1)}_thumbnail.${extension}` ||
        defaultImage
      }
      alt={alt}
      height={height}
      width={width}
      loading="lazy"
      onError={handleImageThumbnailError}
    />
  ) : (
    <img
      id={id}
      className={`${styles.img} ${className ? className : ''}`}
      style={imageStyles}
      src={image || defaultImage}
      alt={alt}
      height={height}
      width={width}
      loading="lazy"
      onError={handleImageError}
    />
  );

  return (
    <>
      {text.length > 0 && !src && (
        <p
          className={`${styles.letter} ${
            height === '32px' || height === '40px'
              ? styles['in-small-avatar']
              : ''
          } ${className ? className : ''}`}
        >
          {text.substring(0, 1).toUpperCase()}
        </p>
      )}
      {(!text.length > 0 || src) && pictures}
    </>
  );
};

export default Image;

// import styles from './image.module.scss';
// import defaultImage from './defaultImage.png';

// const Image = (props) => {
//   const {
//     src,
//     alt = 'Смаколик',
//     height,
//     width,
//     className,
//     style,
//     text = '',
//     id,
//   } = props;
//   const imageStyles = {
//     ...style,
//   };

//   const imageDetecting = (src) => {
//     if (typeof src === 'object') {
//       return src[0]; // Якщо src - масив, повертаємо перший елемент
//     }
//     if (typeof src === 'string') {
//       return src; // Якщо src - строка, повертаємо саме її
//     }
//     return defaultImage; // В інших випадках повертаємо значення за замовчуванням
//   };

//   const getFileExtension = (filename) => {
//     return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
//   };

//   const getStaticImageUrl = (src) => {
//     // Логіка для отримання статичного URL на основі абсолютного src
//     // Це приклад, вам потрібно налаштувати цей метод відповідно до вашого середовища
//     const absoluteBaseUrl = 'https://ваш-домен.com'; // Замініть на ваш базовий URL
//     const relativePath = src.replace('/абсолютний/шлях/', ''); // Замініть на логіку, яка відкидає абсолютний шлях
//     return `${absoluteBaseUrl}/${relativePath}`;
//   };

//   const image = imageDetecting(src);
//   const extension = getFileExtension(image);
//   const staticImageUrl = getStaticImageUrl(image);

//   return (
//     <>
//       {text.length > 0 && !src && (
//         <p
//           className={`${styles.letter} ${
//             height === '32px' || height === '40px' ? styles['in-small-avatar'] : ''
//           } ${className ? className : ''}`}
//         >
//           {text.substring(0, 1).toUpperCase()}
//         </p>
//       )}
//       {(!text.length > 0 || src) && (
//         <picture>
//           <source
//             srcSet={ `${staticImageUrl.slice(0, -extension.length - 1)}_small.${extension} 1x, ${staticImageUrl.slice(0, -extension.length - 1)}_small@2x.${extension} 2x, ${staticImageUrl.slice(0, -extension.length - 1)}_small@3x.${extension} 3x`}
//             media="(max-width: 767px)"
//           />
//           <source
//             srcSet={ `${staticImageUrl.slice(0, -extension.length - 1)}_medium.${extension} 1x, ${staticImageUrl.slice(0, -extension.length - 1)}_medium@2x.${extension} 2x, ${staticImageUrl.slice(0, -extension.length - 1)}_medium@3x.${extension} 3x`}
//             media="(max-width: 1199px)"
//           />
//           <source
//             srcSet={ `${staticImageUrl.slice(0, -extension.length - 1)}_large.${extension} 1x, ${staticImageUrl.slice(0, -extension.length - 1)}_large@2x.${extension} 2x, ${staticImageUrl.slice(0, -extension.length - 1)}_large@3x.${extension} 3x`}
//             media="(min-width: 1280px)"
//           />
//           <img
//             id={id}
//             className={`${styles.img} ${className ? className : ''}`}
//             style={imageStyles}
//             src={staticImageUrl || defaultImage}
//             alt={alt}
//             height={height}
//             width={width}
//             loading="lazy"
//           />
//         </picture>
//       )}
//     </>
//   );
// };

// export default Image;

// import React from 'react';
// import styles from './image.module.scss';
// import defaultImage from './defaultImage.png';
// // console.log('defaultImage', defaultImage);

// const Image = props => {
//   const {
//     src,
//     alt = 'Смаколик',
//     height,
//     width,
//     className,
//     style,
//     text = '',
//     id,
//   } = props;

//   const imageDetecting = src => {
//     // console.log('src image', src);
//     if (Array.isArray(src) && src.length > 0) {
//       return src[0];
//     }
//     if (typeof src === 'string') {
//       return src;
//     }
//     return defaultImage;
//   };

//   const getFileExtension = filename => {
//     return filename
//       ? filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2)
//       : '';
//   };

//   const image = defaultImage ? imageDetecting(src) : '';
//   const extension = image ? getFileExtension(image) : '';

//   const imageStyles = {
//     ...style,
//   };

//   const renderPicture = image && (
//          <img
//         id={id}
//         className={`${styles.img} ${className || ''}`}
//         style={imageStyles}
//         src={image}
//         alt={alt}
//         height={height}
//         width={width}
//         loading="lazy"
//       />
//     );

//   return (
//     <>
//       {text && !src && (
//         <p
//           className={`${styles.letter} ${
//             (height === '32px' || height === '40px') &&
//             styles['in-small-avatar']
//           } ${className || ''}`}
//         >
//           {text[0].toUpperCase()}
//         </p>
//       )}
//       {(!text || src) && renderPicture}
//       {(!text || src) && !image && (
//         <img
//           id={id}
//           className={`${styles.img} ${className || ''}`}
//           style={imageStyles}
//           src={defaultImage}
//           alt={alt}
//           height={height}
//           width={width}
//           loading="lazy"
//         />
//       )}
//     </>
//   );
// };

// export default Image;

// // import styles from './image.module.scss';
// // import defaultImage from './defaultImage.png';

// // const Image = (props) => {
// //   const {
// //     src,
// //     alt = 'Смаколик',
// //     height,
// //     width,
// //     className,
// //     style,
// //     text = '',
// //     id,
// //   } = props;

// //   const imageStyles = {
// //     ...style,
// //   };

// //   const imageDetecting = (src) => {
// //     if (Array.isArray(src)) {
// //       return src[0];
// //     }
// //     if (typeof src === 'string') {
// //       return src;
// //     }
// //     return defaultImage;
// //   };

// //   const getFileExtension = (filename) => {
// //     return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
// //   };

// //   const image = imageDetecting(src);
// //   const extension = getFileExtension(image);
// //   console.log(extension);

// //   return (
// //     <>
// //       {text && !src && (
// //         <p
// //           className={`${styles.letter} ${
// //             (height === '32px' || height === '40px') ? styles['in-small-avatar'] : ''
// //           } ${className || ''}`}
// //         >
// //           {text[0].toUpperCase()}
// //         </p>
// //       )}
// //       {(!text || src) && (
// //         <picture>
// //           <source
// //             srcSet={`${image.slice(0, -extension.length - 1)}_small.${extension} 1x, ${image.slice(0, -extension.length - 1)}_small@2x.${extension} 2x, ${image.slice(0, -extension.length - 1)}_small@3x.${extension} 3x`}
// //             media="(max-width: 767px)"
// //           />
// //           <source
// //             srcSet={`${image.slice(0, -extension.length - 1)}_medium.${extension} 1x, ${image.slice(0, -extension.length - 1)}_medium@2x.${extension} 2x, ${image.slice(0, -extension.length - 1)}_medium@3x.${extension} 3x`}
// //             media="(max-width: 1199px)"
// //           />
// //           <source
// //             srcSet={`${image.slice(0, -extension.length - 1)}_large.${extension} 1x, ${image.slice(0, -extension.length - 1)}_large@2x.${extension} 2x, ${image.slice(0, -extension.length - 1)}_large@3x.${extension} 3x`}
// //             media="(min-width: 1280px)"
// //           />
// //           <img
// //             id={id}
// //             className={`${styles.img} ${className || ''}`}
// //             style={imageStyles}
// //             src={image || defaultImage}
// //             alt={alt}
// //             height={height}
// //             width={width}
// //             loading="lazy"
// //           />
// //         </picture>
// //       )}
// //     </>
// //   );
// // };

// // export default Image;
