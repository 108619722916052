import * as React from 'react';

function HeartIcon({ checked, ...props }) {
  return (
    // <svg
    //   className={checked ? 'heart_icon checked' : 'heart_icon'}
    //   width={32}
    //   height={32}
    //   fill="none"
    //   stroke="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   {...props}
    // >
    //   <path
    //     d="M 15.5 27 C 15.054688 27 14.621094 26.863281 14.253906 26.609375 C 8.816406 22.855469 6.460938 20.285156 5.164062 18.675781 C 2.394531 15.25 1.070312 11.730469 1.105469 7.917969 C 1.152344 3.550781 4.597656 0 8.792969 0 C 11.84375 0 13.957031 1.746094 15.1875 3.199219 C 15.226562 3.246094 15.273438 3.28125 15.328125 3.308594 C 15.382812 3.332031 15.441406 3.34375 15.5 3.34375 C 15.558594 3.34375 15.617188 3.332031 15.671875 3.308594 C 15.726562 3.28125 15.773438 3.246094 15.8125 3.199219 C 17.042969 1.746094 19.15625 0 22.207031 0 C 26.402344 0 29.847656 3.550781 29.894531 7.921875 C 29.929688 11.730469 28.605469 15.25 25.835938 18.679688 C 24.539062 20.285156 22.183594 22.859375 16.746094 26.609375 C 16.378906 26.863281 15.945312 27 15.5 27 Z M 15.5 27 "
    //     // fill={checked ? 'rgba(243, 166, 16, 1)' : 'transparent'}
    //     // stroke={checked ? 'rgba(243, 166, 16, 1)' : 'rgba(243, 166, 16, 1)'}
    //     strokeWidth="1"
    //   />
    // </svg>
    <svg
      className={checked ? 'heart_icon checked' : 'heart_icon'}
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#prefix__clip0_44_5)">
        <path
          d="M22.167 1C27.45 1 31 6.588 31 11.8 31 22.356 16.267 31 16 31c-.267 0-15-8.644-15-19.2C1 6.587 4.55 1 9.833 1 12.867 1 14.85 2.706 16 4.206 17.15 2.706 19.133 1 22.167 1z"
          // fill="#F3A610"
        />
        <path
          d="M22.167 1C27.45 1 31 6.588 31 11.8 31 22.356 16.267 31 16 31c-.267 0-15-8.644-15-19.2C1 6.587 4.55 1 9.833 1 12.867 1 14.85 2.706 16 4.206 17.15 2.706 19.133 1 22.167 1z"
          // stroke="#F3A610"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_44_5">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default HeartIcon;
