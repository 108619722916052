import { useState, useEffect } from 'react';
import styles from './OfflineNotification.scss';

const OfflineNotification = () => {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOffline(false);
    const handleOffline = () => setIsOffline(true);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <>
      {isOffline && (
        <div className="offline-notification">
          Ви перебуваєте в офлайн режимі. Деякі функції можуть бути недоступні.
        </div>
      )}
    </>
  );
};

export default OfflineNotification;
