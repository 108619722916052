// color = stroke

export default function CrossIcon({ large, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={large ? '32' : '16'}
      height={large ? '32' : '16'}
      viewBox={large ? '0 0 32 32' : '0 0 16 16'}
      // fill={large ? 'inherit' : 'none'}
      stroke={large ? 'inherit' : 'none'}
      {...props}
    >
      {large ? (
        <path
          d="M23.6191 23.619L8.38098 8.38095M23.6191 8.38095L8.38098 23.619"
          stroke="inherit"
          strokeWidth="1.52381"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.7111 3.04427C13.7607 2.99468 13.8 2.93581 13.8268 2.87102C13.8537 2.80623 13.8675 2.73679 13.8675 2.66667C13.8675 2.59654 13.8537 2.5271 13.8268 2.46231C13.8 2.39752 13.7607 2.33865 13.7111 2.28907C13.6615 2.23948 13.6026 2.20015 13.5378 2.17331C13.4731 2.14647 13.4036 2.13266 13.3335 2.13266C13.2634 2.13266 13.1939 2.14647 13.1291 2.17331C13.0643 2.20015 13.0055 2.23948 12.9559 2.28907L8.00015 7.24587L3.04442 2.28907C2.99483 2.23948 2.93596 2.20015 2.87118 2.17331C2.80639 2.14647 2.73695 2.13266 2.66682 2.13266C2.59669 2.13266 2.52725 2.14647 2.46246 2.17331C2.39768 2.20015 2.33881 2.23948 2.28922 2.28907C2.23963 2.33865 2.2003 2.39752 2.17346 2.46231C2.14663 2.5271 2.13281 2.59654 2.13281 2.66667C2.13281 2.73679 2.14663 2.80623 2.17346 2.87102C2.2003 2.93581 2.23963 2.99468 2.28922 3.04427L7.24602 8L2.28922 12.9557C2.18907 13.0559 2.13281 13.1917 2.13281 13.3333C2.13281 13.475 2.18907 13.6108 2.28922 13.7109C2.38937 13.8111 2.52519 13.8673 2.66682 13.8673C2.80845 13.8673 2.94427 13.8111 3.04442 13.7109L8.00015 8.75413L12.9559 13.7109C13.056 13.8111 13.1919 13.8673 13.3335 13.8673C13.4751 13.8673 13.6109 13.8111 13.7111 13.7109C13.8112 13.6108 13.8675 13.475 13.8675 13.3333C13.8675 13.1917 13.8112 13.0559 13.7111 12.9557L8.75429 8L13.7111 3.04427Z"
          fill="inherit"
          stroke="inherit"
        />
      )}
    </svg>
  );
}

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.7111 3.04427C13.7607 2.99468 13.8 2.93581 13.8268 2.87102C13.8537 2.80623 13.8675 2.73679 13.8675 2.66667C13.8675 2.59654 13.8537 2.5271 13.8268 2.46231C13.8 2.39752 13.7607 2.33865 13.7111 2.28907C13.6615 2.23948 13.6026 2.20015 13.5378 2.17331C13.4731 2.14647 13.4036 2.13266 13.3335 2.13266C13.2634 2.13266 13.1939 2.14647 13.1291 2.17331C13.0643 2.20015 13.0055 2.23948 12.9559 2.28907L8.00015 7.24587L3.04442 2.28907C2.99483 2.23948 2.93596 2.20015 2.87118 2.17331C2.80639 2.14647 2.73695 2.13266 2.66682 2.13266C2.59669 2.13266 2.52725 2.14647 2.46246 2.17331C2.39768 2.20015 2.33881 2.23948 2.28922 2.28907C2.23963 2.33865 2.2003 2.39752 2.17346 2.46231C2.14663 2.5271 2.13281 2.59654 2.13281 2.66667C2.13281 2.73679 2.14663 2.80623 2.17346 2.87102C2.2003 2.93581 2.23963 2.99468 2.28922 3.04427L7.24602 8L2.28922 12.9557C2.18907 13.0559 2.13281 13.1917 2.13281 13.3333C2.13281 13.475 2.18907 13.6108 2.28922 13.7109C2.38937 13.8111 2.52519 13.8673 2.66682 13.8673C2.80845 13.8673 2.94427 13.8111 3.04442 13.7109L8.00015 8.75413L12.9559 13.7109C13.056 13.8111 13.1919 13.8673 13.3335 13.8673C13.4751 13.8673 13.6109 13.8111 13.7111 13.7109C13.8112 13.6108 13.8675 13.475 13.8675 13.3333C13.8675 13.1917 13.8112 13.0559 13.7111 12.9557L8.75429 8L13.7111 3.04427Z"
    fill="#191C2C"
  />
</svg>;
