export default function BasketLight({ width = 32, height = 32 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M26.4464 10.69H23.5096L21.8492 4.5009C21.7985 4.32831 21.6832 4.18197 21.5272 4.09246C21.3711 4.00296 21.1866 3.9772 21.012 4.02059C20.9247 4.04264 20.8427 4.08204 20.771 4.13644C20.6992 4.19085 20.6391 4.25914 20.5943 4.33725C20.5495 4.41536 20.5209 4.5017 20.5101 4.59111C20.4994 4.68052 20.5067 4.77119 20.5317 4.8577L22.0962 10.69H10.3904L11.9548 4.8577C11.9775 4.77029 11.9827 4.67926 11.9702 4.58984C11.9576 4.50041 11.9275 4.41435 11.8816 4.33658C11.8357 4.25881 11.7749 4.19086 11.7027 4.13664C11.6305 4.08241 11.5483 4.04298 11.4608 4.02059C11.2863 3.97894 11.1026 4.00546 10.947 4.09472C10.7914 4.18399 10.6758 4.32926 10.6237 4.5009L8.97692 10.69H6.02646C5.5244 10.6978 5.04252 10.8888 4.67149 11.2272C4.30047 11.5655 4.0659 12.0278 4.01193 12.527C3.95796 13.0262 4.08832 13.5279 4.37846 13.9377C4.66861 14.3475 5.09853 14.6371 5.58732 14.752L6.60283 25.4148C6.67909 26.2676 7.07242 27.0607 7.70509 27.6374C8.33776 28.2142 9.16375 28.5327 10.0199 28.53H22.453C23.3091 28.5327 24.1351 28.2142 24.7677 27.6374C25.4004 27.0607 25.7937 26.2676 25.87 25.4148L26.8855 14.752C27.3743 14.6371 27.8042 14.3475 28.0944 13.9377C28.3845 13.5279 28.5149 13.0262 28.4609 12.527C28.4069 12.0278 28.1724 11.5655 27.8013 11.2272C27.4303 10.8888 26.9484 10.6978 26.4464 10.69ZM24.5114 25.2913C24.4634 25.8033 24.2256 26.2788 23.8446 26.6242C23.4636 26.9696 22.9672 27.1599 22.453 27.1577H10.0199C9.50564 27.1599 9.0092 26.9696 8.62824 26.6242C8.24728 26.2788 8.0094 25.8033 7.96141 25.2913L6.97335 14.8069H25.4995L24.5114 25.2913ZM26.4464 13.4346H6.02646C5.84536 13.4318 5.67248 13.3586 5.54441 13.2305C5.41633 13.1024 5.34314 12.9296 5.3403 12.7485C5.3403 12.5665 5.4126 12.392 5.54127 12.2633C5.66995 12.1346 5.84448 12.0623 6.02646 12.0623H26.4464C26.6284 12.0623 26.8029 12.1346 26.9316 12.2633C27.0602 12.392 27.1325 12.5665 27.1325 12.7485C27.1297 12.9296 27.0565 13.1024 26.9284 13.2305C26.8004 13.3586 26.6275 13.4318 26.4464 13.4346Z"
        fill="#2B2A29"
      />
      <path
        d="M12.6667 23.2427C12.4899 23.2427 12.3203 23.1724 12.1953 23.0474C12.0702 22.9224 12 22.7528 12 22.576V18.576C12 18.3992 12.0702 18.2296 12.1953 18.1046C12.3203 17.9796 12.4899 17.9093 12.6667 17.9093C12.8435 17.9093 13.013 17.9796 13.1381 18.1046C13.2631 18.2296 13.3333 18.3992 13.3333 18.576V22.576C13.3333 22.7528 13.2631 22.9224 13.1381 23.0474C13.013 23.1724 12.8435 23.2427 12.6667 23.2427ZM19.3333 23.2427C19.1565 23.2427 18.987 23.1724 18.8619 23.0474C18.7369 22.9224 18.6667 22.7528 18.6667 22.576V18.576C18.6667 18.3992 18.7369 18.2296 18.8619 18.1046C18.987 17.9796 19.1565 17.9093 19.3333 17.9093C19.5101 17.9093 19.6797 17.9796 19.8047 18.1046C19.9298 18.2296 20 18.3992 20 18.576V22.576C20 22.7528 19.9298 22.9224 19.8047 23.0474C19.6797 23.1724 19.5101 23.2427 19.3333 23.2427Z"
        fill="#2B2A29"
      />
    </svg>
  );
}
