export default function SettingsWheelIcon({ unfilled, ...props }) {
  return (
    <svg
      width={unfilled ? 24 : 16}
      height={unfilled ? 24 : 16}
      stroke={unfilled && 'none'}
      // fill={!unfilled && 'none'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {unfilled ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.503 6.516a8.49 8.49 0 00-1.5 2.6l1.247.718c1.667.962 1.667 3.37 0 4.331l-.5-.867a1.5 1.5 0 000-2.598l-1.6-.924a.5.5 0 01-.228-.58 9.486 9.486 0 012.11-3.657.5.5 0 01.617-.093l1.601.924-.5.866-1.247-.72zM9.5 3.223v1.849A1.5 1.5 0 017.25 6.37l-.5.867c1.667.962 3.75-.24 3.75-2.165V3.631a8.49 8.49 0 013 .002v1.439c0 1.924 2.083 3.127 3.75 2.165l1.247-.72a8.467 8.467 0 011.5 2.597l-1.247.72c-1.667.962-1.667 3.37 0 4.332l1.246.719a8.49 8.49 0 01-1.498 2.598l-1.248-.721c-1.667-.96-3.75.24-3.75 2.165v1.442a8.494 8.494 0 01-3-.001v-1.441c0-1.924-2.083-3.126-3.75-2.164v-.001l-1.247.72a8.521 8.521 0 01-1.5-2.598l1.246-.72-.499-.866-1.602.924a.5.5 0 00-.228.581l.478-.148-.25-.433.251.434-.478.148a9.543 9.543 0 002.11 3.655.5.5 0 00.617.093l1.601-.924-.2-.346.201.347a1.5 1.5 0 012.25 1.298v1.847h-.002a.5.5 0 00.39.488 9.496 9.496 0 004.223.003v-.001a.5.5 0 00.388-.487h.001v-1.85a1.498 1.498 0 011.5-1.499c.263 0 .522.07.75.201l1.6.924a.5.5 0 00.617-.093l.001.001a9.49 9.49 0 002.11-3.658l-.478-.147v-.001l.476.147a.5.5 0 00-.226-.579l-.001-.002-1.599-.92-.5.865.5-.867a1.5 1.5 0 010-2.599l1.6-.924a.5.5 0 00.228-.58h.001a9.521 9.521 0 00-.852-1.946 9.523 9.523 0 00-1.259-1.71l-.367.34v-.002l.366-.34a.5.5 0 00-.617-.093v.001l-1.6.925a1.5 1.5 0 01-2.25-1.299V3.225a.5.5 0 00-.389-.487 9.49 9.49 0 00-4.222-.003.5.5 0 00-.39.488H9.5zm.5-.001l-.111-.487.11.487zm8.855 15.135l-.255-.237.255.236zm-8.967 2.905l.11-.488H10l-.112.488zm-4.49-3.142l.25.432-.249-.432zM12 9.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM10.5 12a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z"
          fill="inherit"
        />
      ) : (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 1.85771C9.97255 2.01483 10.8696 2.39737 11.6358 2.95L12.9498 1.63609L14.364 3.0503L13.05 4.36422C13.6027 5.13044 13.9852 6.02748 14.1423 7H16V9H14.1423C13.9852 9.97252 13.6027 10.8696 13.05 11.6358L14.364 12.9497L12.9498 14.3639L11.6358 13.05C10.8696 13.6026 9.97255 13.9852 9 14.1423V16H7V14.1423C6.02748 13.9852 5.13044 13.6027 4.36422 13.05L3.05026 14.364L1.63605 12.9498L2.95 11.6358C2.39737 10.8696 2.01483 9.97255 1.85771 9H0V7H1.85771C2.01483 6.02745 2.39737 5.13039 2.95 4.36416L1.63605 3.05021L3.05026 1.63599L4.36422 2.94996C5.13044 2.39735 6.02748 2.01483 7 1.85771V0H9V1.85771ZM9.22222 8C9.22222 7.32499 8.67501 6.77778 8 6.77778C7.32499 6.77778 6.77778 7.32499 6.77778 8C6.77778 8.67501 7.32499 9.22222 8 9.22222C8.67502 9.22222 9.22222 8.67502 9.22222 8Z"
          fill="inherit"
        />
      )}
    </svg>
  );
}

<svg
  xmlns="http://www.w3.org/2000/svg"
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M9 1.85771C9.97255 2.01483 10.8696 2.39737 11.6358 2.95L12.9498 1.63609L14.364 3.0503L13.05 4.36422C13.6027 5.13044 13.9852 6.02748 14.1423 7H16V9H14.1423C13.9852 9.97252 13.6027 10.8696 13.05 11.6358L14.364 12.9497L12.9498 14.3639L11.6358 13.05C10.8696 13.6026 9.97255 13.9852 9 14.1423V16H7V14.1423C6.02748 13.9852 5.13044 13.6027 4.36422 13.05L3.05026 14.364L1.63605 12.9498L2.95 11.6358C2.39737 10.8696 2.01483 9.97255 1.85771 9H0V7H1.85771C2.01483 6.02745 2.39737 5.13039 2.95 4.36416L1.63605 3.05021L3.05026 1.63599L4.36422 2.94996C5.13044 2.39735 6.02748 2.01483 7 1.85771V0H9V1.85771ZM9.22222 8C9.22222 7.32499 8.67501 6.77778 8 6.77778C7.32499 6.77778 6.77778 7.32499 6.77778 8C6.77778 8.67501 7.32499 9.22222 8 9.22222C8.67502 9.22222 9.22222 8.67502 9.22222 8Z"
    fill="#191C2C"
  />
</svg>;
